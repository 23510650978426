<script setup lang="ts">
/* eslint-disable max-len */
export interface IconSearch {
  width?: number;
  height?: number;
}

const props = withDefaults(defineProps<IconSearch>(), {
  width: 16,
  height: 16,
});
</script>

<template>
  <svg :width="props.width" :height="props.height" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m19.64 17.896-4.6-4.6c2.402-3.256 2.12-7.93-.804-10.854A8.287 8.287 0 0 0 8.339 0a8.284 8.284 0 0 0-5.896 2.442A8.285 8.285 0 0 0 0 8.338c0 2.228.867 4.322 2.443 5.897a8.283 8.283 0 0 0 5.896 2.443c1.803 0 3.53-.575 4.96-1.636l4.597 4.597c.233.233.543.361.872.361.33 0 .64-.129.87-.36.233-.233.362-.542.362-.872 0-.33-.128-.639-.36-.872zM8.338 2.465c1.568 0 3.043.61 4.153 1.72A5.833 5.833 0 0 1 14.21 8.34c0 1.57-.61 3.046-1.718 4.153a5.835 5.835 0 0 1-4.153 1.72 5.836 5.836 0 0 1-4.154-1.72 5.834 5.834 0 0 1-1.72-4.153c0-1.57.61-3.044 1.72-4.154a5.837 5.837 0 0 1 4.154-1.72z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>
