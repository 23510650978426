<script setup lang="ts">
/* eslint-disable max-len */

export interface IconEye {
  active: boolean;
  isDarkTheme: boolean;
}
const props = defineProps<IconEye>();
</script>

<template>
  <svg v-if="props.active" width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>hide</title>
    <path v-if="props.isDarkTheme" d="M9,3 C12.3220826,3 15.4890485,4.62558331 17.4667864,7.36121976 C18.1777379,8.33911473 18.1777379,9.66069647 17.4667864,10.6387802 C15.4890115,13.3613142 12.3220826,15 9,15 C5.67795435,15 2.51095153,13.3744167 0.533213645,10.6387802 C-0.177737882,9.66088527 -0.177737882,8.33930353 0.533213645,7.36121976 C2.51098846,4.63868584 5.67791741,3 9,3 Z M8.90951526,5.72249615 C7.16445242,5.72249615 5.74254937,7.17623607 5.74254937,8.96037143 C5.74254937,10.7445068 7.16445242,12.1982467 8.90951526,12.1982467 C10.6545781,12.1982467 12.0764811,10.7445068 12.0764811,8.96037143 C12.0764811,7.17623607 10.6545781,5.72249615 8.90951526,5.72249615 Z M8.90951526,7.05729372 C9.94362657,7.05729372 10.783842,7.90303051 10.783842,8.96029591 C10.783842,10.0175613 9.94362657,10.8765894 8.90951526,10.8765894 C7.87540395,10.8765894 7.03518851,10.0308526 7.03518851,8.97358724 C7.03518851,7.91632185 7.87540395,7.05729372 8.90951526,7.05729372 Z" fill="#FFFFFF" />
    <path v-else id="Icon/Eye/Selected" d="M9,3 C12.3220826,3 15.4890485,4.62558331 17.4667864,7.36121976 C18.1777379,8.33911473 18.1777379,9.66069647 17.4667864,10.6387802 C15.4890115,13.3613142 12.3220826,15 9,15 C5.67795435,15 2.51095153,13.3744167 0.533213645,10.6387802 C-0.177737882,9.66088527 -0.177737882,8.33930353 0.533213645,7.36121976 C2.51098846,4.63868584 5.67791741,3 9,3 Z M8.90951526,5.72249615 C7.16445242,5.72249615 5.74254937,7.17623607 5.74254937,8.96037143 C5.74254937,10.7445068 7.16445242,12.1982467 8.90951526,12.1982467 C10.6545781,12.1982467 12.0764811,10.7445068 12.0764811,8.96037143 C12.0764811,7.17623607 10.6545781,5.72249615 8.90951526,5.72249615 Z M8.90951526,7.05729372 C9.94362657,7.05729372 10.783842,7.90303051 10.783842,8.96029591 C10.783842,10.0175613 9.94362657,10.8765894 8.90951526,10.8765894 C7.87540395,10.8765894 7.03518851,10.0308526 7.03518851,8.97358724 C7.03518851,7.91632185 7.87540395,7.05729372 8.90951526,7.05729372 Z" fill="#AFB6C1" />

  </svg>
  <svg v-else width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>show</title>
    <path d="M9 3c3.322 0 6.489 1.626 8.467 4.361.71.978.71 2.3 0 3.278C15.489 13.36 12.322 15 9 15c-3.322 0-6.489-1.626-8.467-4.361a2.785 2.785 0 0 1 0-3.278C2.511 4.64 5.678 3 9 3zm0 1.335c-2.96 0-5.662 1.387-7.433 3.82a1.433 1.433 0 0 0 0 1.704c1.771 2.432 4.473 3.82 7.433 3.82s5.662-1.388 7.433-3.82a1.433 1.433 0 0 0 0-1.705C14.662 5.723 11.96 4.334 9 4.334zm-.09 1.387c1.745 0 3.166 1.454 3.166 3.238 0 1.785-1.421 3.238-3.166 3.238-1.746 0-3.167-1.453-3.167-3.238 0-1.784 1.421-3.238 3.167-3.238zm0 1.335c-1.035 0-1.875.86-1.875 1.917s.84 1.903 1.875 1.903c1.034 0 1.874-.86 1.874-1.917s-.84-1.903-1.874-1.903z" fill="var(--base-icon-color)" fill-rule="evenodd" />
  </svg>
</template>
