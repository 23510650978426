<script setup lang="ts">
/* eslint-disable max-len */
export interface IconCopyProps {
  fill?: string;
}
const props = withDefaults(defineProps<IconCopyProps>(), { fill: "#A9B7D1" });
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.908 4.32v13.94c.001.73.287 1.43.795 1.946a2.693 2.693 0 0 0 1.916.807h9.16v1.567c0 .376-.147.738-.41 1.004a1.387 1.387 0 0 1-.988.416H3.398c-.37 0-.726-.15-.989-.416A1.432 1.432 0 0 1 2 22.58V5.74c0-.376.147-.737.41-1.004.262-.266.617-.416.988-.416h2.51zM16.947 0v5.48c0 .177.069.346.192.471s.29.195.464.195H23V18.26c0 .376-.147.737-.41 1.004a1.387 1.387 0 0 1-.988.416H8.619c-.37 0-.726-.15-.988-.416a1.432 1.432 0 0 1-.41-1.004V1.42c0-.376.147-.738.41-1.004C7.893.15 8.248 0 8.619 0h8.328zm1.312.39 4.356 4.423H18.26V.391z" :fill="props.fill" fill-rule="evenodd" />
  </svg>
</template>
